// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

// Variables
@import "variables-backend";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

.router-link-exact-active {
    background: $primary-light !important;
    text-decoration: none;

    i {
        color: $primary !important;
    }

    .v-list-item__title {
        color: $primary !important;
    }
}

.actions-hover-red {
    background: $error-light !important;
}

.actions-hover-blue {
    background: $primary-light !important;
}

.actions-hover-cyan {
    background: $info-light !important;
}

.v-toolbar__content,
.v-tabs.theme--dark .v-tabs-bar__content {
    background: -webkit-gradient(
        linear,
        left top,
        right bottom,
        from(#2196f3),
        to(#00b0ff)
    ) !important;
    background: linear-gradient(to bottom right, #2196f3, #00b0ff) !important;
}

.v-main {
    background-color: $primary-light;
}

.v-navigation-drawer .v-badge__badge {
    zoom: 0.8;
}

label {
    margin-bottom: 0;
}

button:focus {
    outline: none;
}

.v-application {
    .v-navigation-drawer--mini-variant {
        .ap-list-button {
            padding: 15px 0;
        }
    }
    .v-list {
        padding: 0;

        .v-list-item__action {
            margin: 15px 0;
        }
    }

    .v-list--dense .v-list-item .v-list-item__subtitle,
    .v-list--dense .v-list-item .v-list-item__title,
    .v-list-item--dense .v-list-item__subtitle,
    .v-list-item--dense .v-list-item__title {
        font-size: 0.9rem;
        font-weight: 300;
        line-height: 1.2rem;
    }

    header {
        background: $white;
        box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);

        .container {
            padding: 11px;

            .headline {
                color: $blue;
                font-size: 1.3rem !important;
                font-family: $font-family-popins !important;
                margin-bottom: 0;
            }
        }

        .v-text-field {
            margin-top: 0;
            padding-top: 0;
        }
    }

    .v-dialog__content {
        .headline,
        .title {
            font-size: 1.3rem !important;
            font-family: $font-family-popins !important;
        }
    }

    footer {
        color: rgba(0, 0, 0, 0.54) !important;

        a {
            color: $blue;
        }
    }
}

.v-card__title {
    color: $blue;
}

.admin-bg {
    background-color: #0089d1;
}

.v-progress-linear {
    height: 6px !important;
}

.v-icon.itb-icon-tooltip {
    cursor: default;
}

.v-input--switch.v-input:not(.v-input--is-loading) {
    .v-input--switch__thumb,
    .v-input--switch__track,
    .v-input--selection-controls__ripple {
        color: $red;
    }
}

.itb-color-box {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    margin-right: 0.3rem;
    margin-top: 0.3rem;
}

.itb-drawer-label {
    background: rgb(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .v-icon {
        zoom: 80%;
        top: -1px;
        left: 2px;
    }
}

.itb-headline {
    color: $blue;
    font-size: 1.3rem !important;
    font-family: $font-family-popins !important;
    margin-bottom: 0;
}

.itb-subheadline {
    color: rgb($blue, 0.7);
    font-size: 1.1rem !important;
    font-family: $font-family-popins !important;
    margin-bottom: 0;
}

.itb-gallery-size {
    color: $muted;
    font-size: 0.8rem;
}

.itb-text-green {
    color: $green;
}

.itb-text-red {
    color: $red;
}

.v-file-input {
    position: relative;

    input[type="file"] {
        width: 100% !important;
        height: 100%;
        position: absolute !important;
        top: 0px;
        left: 0 !important;
        pointer-events: all !important;
        max-width: none !important;
    }
}
//  Carlos's styles

.itb-discount-input {
    max-width: 127px !important;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
    input {
        text-align: center;
    }
}
.v-tab {
    color:rgb(180, 179, 179) !important;
}
.v-tab--active {
    color: white !important;
}
.table-filter {
    .v-text-field{
        max-width: 150px;
        padding-bottom: 8px;
        padding-top: 8px !important;
    }
    .v-text-field__details {
        display: none;
    }
    .v-input__slot{
        margin: 0;
    }
}
.itb-test-server {
    .v-app-bar {
        .v-toolbar__content,
        .v-tabs.theme--dark
        .v-tabs-bar__content {
        background: #9319d2 !important;
        }
    }
    
  }
